import Markdown from 'markdown-to-jsx'
import type { ComponentProps } from 'react'
import React from 'react'
import styled, { css } from 'styled-components'

import { mobile } from './mixins'
import Link from './modules/Link'
import { Quote } from './modules/PullQuote'
import { List, Bullet } from './modules/TasksList'
import { Color } from './theme'
import {
  Heading3,
  BodyRegular,
  bodyRegularStyle,
  captionRegularStyle,
  bodySemiboldStyle,
} from './typography'

const Article = styled.article`
  ${bodyRegularStyle}
  flex: 2;
  margin-right: 10%;

  ${mobile(css`
    width: 100%;
    margin: auto;
  `)}

  p, ul {
    margin: 24px 0;
  }

  li {
    display: block;

    p {
      margin: 0;
    }
  }

  p + ${Heading3},
  ul + ${Heading3} {
    margin-top: 48px;
  }

  ${Heading3} + p,
  ${Heading3} + ul {
    margin-top: 16px;
  }

  a {
    display: unset;
  }

  img {
    margin: ${80 - 33}px auto;
    max-width: 100%;
    border-radius: 16px;

  ${mobile(css`
    margin: ${64 - 33}px auto;
    border-radius: 8px;
  `)}
`

const Caption = styled.figcaption`
  ${captionRegularStyle}
  color: ${Color.GRAY_5};
  text-align: center;
  margin: ${-80 + 8}px auto 80px;
`

const ALabel = styled.span`
  ${bodySemiboldStyle}
`

type MarkdownRendererProps = ComponentProps<typeof Markdown> & {
  openLinksInNewTab?: boolean
}

export const MarkdownRenderer = ({
  openLinksInNewTab,
  ...props
}: MarkdownRendererProps) => (
  <Markdown
    options={{
      overrides: {
        h2: { component: Heading3 },
        h3: { component: Heading3 },
        h4: { component: Heading3 },
        p: { component: BodyRegular },
        ul: { component: List },
        li: { component: Bullet },
        a: {
          component: Link,
          props: { TextComponent: ALabel, newTab: openLinksInNewTab },
        },
        figcaption: { component: Caption },
        blockquote: { component: Quote, props: { noResponsive: true } },
      },
    }}
    className
    {...props}
  />
)

type Props = ComponentProps<typeof Article> & {
  markdown: string | null | undefined
  openLinksInNewTab?: boolean
}

const MarkdownArticle = ({ markdown, openLinksInNewTab, ...props }: Props) => (
  <Article {...props}>
    <MarkdownRenderer openLinksInNewTab={openLinksInNewTab}>
      {markdown || ''}
    </MarkdownRenderer>
  </Article>
)

export default MarkdownArticle
