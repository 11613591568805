import React from 'react'
import styled from 'styled-components'

import type { PullQuoteFragment } from '../../../graphql-types'
import { Color } from '../theme'
import { subheadRegularStyle } from '../typography'

const Outer = styled.div`
  text-align: center;
  max-width: 720px;
  margin: 24px auto;
`

export const Quote = styled.blockquote`
  &,
  p {
    ${subheadRegularStyle}
    color: ${Color.PURPLE_6};
    text-align: left;
  }

  p {
    display: inline;
  }

  &:before {
    content: '“';
  }
  &:after {
    content: '”';
  }
`

type Props = PullQuoteFragment

const PullQuote = ({ text }: Props) => (
  <Outer>
    <Quote noResponsive>{text?.text}</Quote>
  </Outer>
)

export default PullQuote
