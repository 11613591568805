import React from 'react'
import styled, { css } from 'styled-components'

import type { TextFragment } from '../../../graphql-types'
import Markdown from '../Markdown'
import { Heading2, Heading3, SubheadRegular } from '../typography'
import { renderTextWithLineBreaks } from '../utils'

import _Link from './Link'

const Outer = styled.div`
  text-align: center;
  max-width: 720px;
  margin: 0 auto;
`

const ParagraphBody = styled(Markdown)``

const TitleBig = styled(Heading2)`
  & + ${SubheadRegular} {
    margin-top: 24px;
  }
`
const TitleSmall = styled(Heading3)`
  & + ${SubheadRegular}, & + ${ParagraphBody} {
    margin-top: 8px;
  }
`

const Link = styled(_Link)`
  margin-top: ${({ button }) => (button ? 48 : 24)}px;
`

export enum TextType {
  CENTERED_TEXT = 'Centered Text',
  CTA = 'CTA',
  PARAGRAPH = 'Paragraph',
  FEATURE_TEXT = 'Feature Text',
}

type Props = Omit<TextFragment, 'type'> & {
  type?: TextType | string | null // eslint-disable-line react/no-unused-prop-types
}

const ParagraphOuter = styled(Outer)<{ extraSpacing?: boolean | null }>`
  padding: 8px 0;
  text-align: left !important;

  ${({ extraSpacing }) =>
    extraSpacing &&
    css`
      padding: 24px 0;
    `}
`

const Paragraph = ({ title, text, extraSpacing, anchor }: Props) => (
  <ParagraphOuter extraSpacing={extraSpacing} id={anchor || undefined}>
    <TitleSmall>{title}</TitleSmall>
    {!!text?.text && <ParagraphBody markdown={text.text} />}
  </ParagraphOuter>
)

const Cta = ({ title, text, link, anchor }: Props) => (
  <Outer id={anchor || undefined}>
    {!!title && <TitleBig noResponsive>{title}</TitleBig>}
    {!!text?.text && <SubheadRegular noResponsive>{text.text}</SubheadRegular>}
    <Link {...link} id={'text-cta'} />
  </Outer>
)

const FeatureText = ({ title, text, link, anchor }: Props) => (
  <Outer id={anchor || undefined}>
    <TitleSmall noResponsive>{title}</TitleSmall>
    {!!text?.text && (
      <SubheadRegular>{renderTextWithLineBreaks(text.text)}</SubheadRegular>
    )}
    <Link {...link} id={'text-cta'} />
  </Outer>
)

const CenteredText = ({ title, text, link, anchor }: Props) => (
  <Outer id={anchor || undefined}>
    {!!title && <TitleBig noResponsive>{title}</TitleBig>}
    {!!text?.text && (
      <SubheadRegular>{renderTextWithLineBreaks(text.text)}</SubheadRegular>
    )}
    <Link {...link} id={'text-cta'} />
  </Outer>
)

const Text = ({ type, ...props }: Props) => {
  switch (type ?? TextType.CENTERED_TEXT) {
    case TextType.PARAGRAPH:
      return <Paragraph {...props} />
    case TextType.FEATURE_TEXT:
      return <FeatureText {...props} />
    case TextType.CTA:
      return <Cta {...props} />
    case TextType.CENTERED_TEXT:
    default:
      return <CenteredText {...props} />
  }
}

export default Text
