import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import React from 'react'

import type {
  ResourcePagesListQuery,
  ResourcePagesListQueryVariables,
} from '../../../graphql-types'
import PaginationLink, {
  PaginationOuter,
} from '../../components/PaginationLink'
import Page from '../../components/layout/Page'
import Container from '../../components/modules/Container'
import Hero from '../../components/modules/Hero'
import Spacer from '../../components/modules/Spacer'
import Footer from '../../components/resource/Footer'
import Preview from '../../components/resource/Preview'
import Grid from '../../components/resource/PreviewsGrid'

export type Query = ResourcePagesListQuery
export type QueryVariables = ResourcePagesListQueryVariables
export type Context = QueryVariables & {
  categorySlug: string | null
  nextPagePath?: string | null
  prevPagePath?: string | null
}

export const query = graphql`
  query ResourcePagesList(
    $langKey: String!
    $where: ContentfulResourcePageFilterInput
    $limit: Int
    $skip: Int
    $categorySlug: String
  ) {
    category: contentfulResourceCategory(
      node_locale: { eq: $langKey }
      slug: { eq: $categorySlug }
    ) {
      name
      slug
    }
    list: allContentfulResourcePage(
      limit: $limit
      skip: $skip
      filter: $where
      sort: { fields: createdAt, order: DESC }
    ) {
      items: nodes {
        ...PreviewResourcePage
      }
    }
  }
`

type Props = PageProps<Query, Context>

const ResourcePagesList = ({ data, pageContext }: Props) => {
  const pages = data.list.items
  const { nextPagePath, prevPagePath } = pageContext

  return (
    <Page
      head={{
        title:
          'Delegation, time management, and leadership resources from Double',
        description:
          'Discover what delegation is and what it looks like in practice with resources from Double.',
      }}
    >
      <Hero
        title={'Delegation resources'}
        text={{
          text: 'Discover what delegation is and what it looks like in practice with resources from Double.',
        }}
      />
      <Spacer size={'Medium'} />

      <Container>
        <Grid itemsCount={pages.length}>
          {pages.map((page) => (
            <Preview key={page.slug} page={page} rootPath={'/resources'} />
          ))}
        </Grid>
      </Container>

      {!!(prevPagePath || nextPagePath) && (
        <>
          <PaginationOuter>
            {!!prevPagePath && (
              <PaginationLink to={prevPagePath}>
                {'← Previous resources'}
              </PaginationLink>
            )}
            {!!nextPagePath && (
              <PaginationLink to={nextPagePath}>
                {'Next resources →'}
              </PaginationLink>
            )}
          </PaginationOuter>
          <Spacer size={'Large'} />
        </>
      )}

      <Footer />
    </Page>
  )
}

export default ResourcePagesList
