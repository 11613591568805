import styled, { css } from 'styled-components'

import { tablet, mobile, minScreenWidth } from '../mixins'

export const PADDING = 36

const Grid = styled.div<{ itemsCount: number }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-between;
  padding: 0 ${PADDING}px;

  ${({ itemsCount }) =>
    itemsCount < 3 &&
    minScreenWidth(1000)(css`
      justify-content: flex-start;
      & > * + * {
        margin-left: 24px !important;
      }
    `)}

  ${tablet(css`
    padding: 0 ${PADDING / 3}px;
  `)}

  ${mobile(css`
    padding: 0 ${PADDING / 3}px;
  `)}
`

export default Grid
