import { Link } from 'gatsby'
import React from 'react'
import styled, { css } from 'styled-components'

import type {
  PreviewCaseStudyFragment,
  PreviewResourcePageFragment,
} from '../../../graphql-types'
import { tablet, mobile, maxScreenWidth } from '../mixins'
import { useAssetUrl } from '../modules/Img'
import { Color } from '../theme'
import { Heading3, CalloutRegular, BodySemibold } from '../typography'

import { PADDING as LIST_PADDING } from './PreviewsGrid'

export const Image = styled.div<{ imageSrc: string }>`
  background-color: ${Color.GRAY_1};
  background-image: url(${({ imageSrc }) => imageSrc});
  background-size: cover;
  background-position: center;
  border-radius: 20px;
`

const Outer = styled.div`
  flex: 0 0 ${(360 / (1200 - LIST_PADDING * 2)) * 100}%;
  background: #ffffff;
  box-shadow: 0px 12px 32px rgba(98, 104, 111, 0.2);
  border-radius: 32px;
  margin: 0 0 72px;

  transition: 0.3s ease-in-out;
  &:hover {
    transform: translateY(-4px) scale(1.02);
    box-shadow: 0px 12px 48px rgba(98, 104, 111, 0.2);
  }

  a {
    display: flex;
    flex-direction: column;
  }

  ${tablet(css`
    flex-basis: 48%;
  `)}

  ${mobile(css`
    flex-basis: 100%;
  `)}
`

const PreviewImage = styled(Image)`
  height: 206px;
  margin: 18px 18px 20px;

  ${tablet(css`
    height: 204px;
  `)}
  ${maxScreenWidth(530)(css`
    margin-bottom: 24px;
  `)}
`

const Title = styled(Heading3)`
  margin: 0 34px;
`

const Cta = styled(CalloutRegular)`
  margin: 20px 34px 31px;
  color: ${Color.PURPLE_6};
`

const Category = styled<{ color?: string | null | undefined }>(BodySemibold)`
  margin: 0 34px 8px;
  ${({ color }) =>
    !!color &&
    css`
      color: ${color?.startsWith('#') ? '' : '#'}${color};
    `}
`

type Props = {
  page: PreviewCaseStudyFragment | PreviewResourcePageFragment
  rootPath: string
}

const Preview = ({ page, rootPath }: Props) => {
  const assetSrc = useAssetUrl({ asset: page.poster, maxHeight: 276 })

  return (
    <Outer>
      <Link to={`${rootPath}/${page.slug}`}>
        <PreviewImage imageSrc={assetSrc} />
        {!!page && 'category' in page && (
          <Category color={page.category?.color}>
            {page.category?.name}
          </Category>
        )}
        <Title noResponsive>{page.title}</Title>
        <Cta>{'Read the article'}</Cta>
      </Link>
    </Outer>
  )
}

export default Preview
