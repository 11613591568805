import type { ComponentProps } from 'react'
import React from 'react'
import styled from 'styled-components'

import type { TasksItemFragment } from '../../../graphql-types'
import CheckIcon from '../../images/Check.inline.svg'
import { Heading3, CalloutRegular, BodyRegular } from '../typography'

const ItemOuter = styled.div`
  flex: 0 0 25%;
  padding: 24px 24px 13px;
  min-width: 240px;

  @media screen and (max-width: 1103px) {
    flex-basis: 33%;
  }

  @media screen and (max-width: 783px) {
    flex-basis: 50%;
  }
`
const ItemTitle = styled(Heading3)`
  margin-bottom: 20px;
`
export const List = styled.ul`
  list-style: none;
`

interface BulletProps {
  compact?: boolean
}

const BulletOuter = styled.li<BulletProps>`
  padding: 1px 0 1px 32px;
  min-height: 28px;
  display: flex;
  align-items: center;

  & + & {
    margin-top: ${({ compact }) => (compact ? '8px !important' : '16px')};
  }

  svg {
    position: absolute;
    left: 0;
    top: 6px;
  }
`

export const Bullet = ({
  children,
  ...props
}: ComponentProps<typeof BulletOuter>) => (
  <BulletOuter {...props}>
    <CheckIcon />
    {children}
  </BulletOuter>
)

const BodyRegularWithMarginBottom = styled(BodyRegular)`
  margin-bottom: 16px;
`

interface TaskProps {
  task: string
  large: boolean
}

const Task = ({ task, large }: TaskProps) =>
  large ? (
    <BodyRegular>{task}</BodyRegular>
  ) : (
    <CalloutRegular noResponsive>{task}</CalloutRegular>
  )

export type TasksItemProps = Pick<TasksItemFragment, 'title' | 'tasks'> & {
  description?: string
  large?: boolean
  compact?: boolean
}

export const TasksItem = ({
  title,
  tasks,
  large,
  compact,
  description,
}: TasksItemProps) => (
  <>
    {title && <ItemTitle>{title}</ItemTitle>}
    <BodyRegularWithMarginBottom>{description}</BodyRegularWithMarginBottom>
    <List>
      {tasks?.map(
        (task, index) =>
          !!task && (
            <Bullet compact={compact || false} key={index}>
              <Task large={large || false} task={task} />
            </Bullet>
          ),
      )}
    </List>
  </>
)

const ListOuter = styled.div`
  display: flex;
  flex-wrap: wrap;
`

type TasksListProps = {
  items: TasksItemProps[]
}

const TasksList = ({ items }: TasksListProps) => (
  <ListOuter>
    {items.map(
      (item, index) =>
        !!item && (
          <ItemOuter key={index}>
            <TasksItem {...item} />
          </ItemOuter>
        ),
    )}
  </ListOuter>
)

export default TasksList
